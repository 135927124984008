<template>
  <div class="font">
    <el-card class="robot-earn" id="robot-earn" shadow="hover" style="position: relative">
      <div v-if="lgShow">
        <div>{{$t('user_center.robot_title_earn')}}</div>
        <div style="position: relative;margin-right: 2px;color: rgb(227, 185, 54);display:inline-block;">
          <div style="display: inline-block">
            <div style="font-size: 14px;color: #72777E;margin-bottom: 0;margin-right: 0; line-height: 30px;">{{$t('user_center.today_earn')}}</div>
            <span style="font-size: 24px;font-weight: 600">{{(add_day*1) | roundTwoFilter}}USDT</span>
          </div>
          <div style="display: inline-block;margin:0 0 0 100px">
            <div style="font-size: 14px;color: #72777E; line-height: 30px;">{{$t('user_center.add_app_earn')}}</div>
            <span style="font-size: 20px;display: inline-block;color: #1E2329">
              <span style="display: inline-block;">{{ (userEarnInfoAll.profit*1) | roundTwoFilter }}USDT</span>
              <span style="display: inline-block">≈</span>
              <span style="display:inline-block;;line-height: 28px">¥{{ (userEarnInfoAll.profit*rate)| roundTwoFilter }}</span>
            </span>
          </div>
        </div>
        <div style="margin: 24px 0 0 0;height: 64px ">
          <div id="add-up-earn" style="width: 120px;height: 64px;margin:0 50px 0 0;display: inline-block;vertical-align: bottom"></div>
          <div style="display: inline-block;margin: 0;vertical-align: top">
            <div style="font-size: 14px;color: #72777E;margin-bottom: 0;margin-right: 0; line-height: 30px;">{{$t('user_center.add_up_rate')}} </div>
            <div  style="font-size: 20px;margin: 0;color: #52C41A;display: inline-block;line-height: 28px"><i class="el-icon-caret-top" style="color: #52C41A"></i>{{ (userEarnInfoAll.yield*100) | roundTwoFilter }}%</div>
          </div>
        </div>
        <div style="display: block; margin-bottom: 10px">
<!--          <div style="font-size: 16px">{{$t('user_center.month_average_rate')}} <span style="color: rgb(82, 196, 26);font-size: 16px">{{(userEarnInfoAll.avg_yield*100).toFixed(2)}}%</span></div>-->
<!--          <div style="font-size: 16px;margin-left: 60px">-->
          <div style="font-size: 16px;margin-left: 0px">
            {{$t('user_center.total_earn_rank')}} <span style="color: #000613;font-size: 16px;">{{Number(userEarnInfoRank.rankNum)>450 ? $t('user_center.no_listed') :userEarnInfoRank.rankNum}}</span>
          </div>
        </div>
      </div>
      <div v-if="smShow"></div>
<!--      <div v-if="smShow" class="sm-add-profit">-->
<!--        <div>-->
<!--          <div style="display:inline-block;width: 50%">-->
<!--            <div style="font-size: 16px;margin-bottom: 6px">{{ $t('user_center.today_earn') }}</div>-->
<!--            <span style="color: #E3B936;font-size: 18px">{{ (add_day * 1).toFixed(2) }}USDT</span>-->
<!--          </div>-->
<!--          <div style="width: 50%;display: inline-block"><span style="font-size: 16px"><div style="margin-bottom: 6px">{{ $t('user_center.add_up_rate') }}</div><i class="el-icon-caret-top" style="color: #52C41A"></i></span><span style="color: #52C41A;font-size: 18px">{{ (userEarnInfoAll.yield * 100).toFixed(2) }}%</span></div>-->
<!--        </div>-->
<!--        <div style="margin-top: 8px">{{ $t('user_center.robot_earn') }}</div>-->
<!--        <div style="margin-bottom: 0;margin-top: 6px"><span style="color: #000613;font-size: 18px">{{ (userEarnInfoAll.profit * 1).toFixed(2) }}USDT</span><span>≈</span> <span style="font-size: 18px;color: #000613">¥{{ (userEarnInfoAll.profit * rate).toFixed(2) }}</span></div>-->
<!--        <div style="width: 100%;margin-bottom: 0">-->
<!--          <div style="margin-top: 20px;width: 50%;display: inline-block;vertical-align: top">-->
<!--            <div style="margin-bottom: 6px">{{ $t('user_center.month_average_rate') }}</div>-->
<!--            <span style="color: #52C41A"> {{ (userEarnInfoAll.avg_yield * 100).toFixed(2) }}%</span>-->
<!--          </div>-->
<!--          <div style="margin-top: 20px;width: 50%;display: inline-block">-->
<!--            <div style="margin-bottom: 6px">{{ $t('user_center.total_earn_rank') }}</div>-->
<!--            <span @click="$router.push('/earn_rank')" style="font-size: 14px">{{ userEarnInfoRank.rankNum }}</span><span><i @click="$router.push('/earn_rank')" style="font-size: 14px;cursor: pointer" class="el-icon-d-arrow-right"></i></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="smShow" class="sm-add-profit" style="height: unset;padding: 20px;position: relative">
        <div style="margin: 0">
          <span style="display: block;color: #72777E;font-size: 15px;font-family: PingFangSC-Regular, PingFang SC;">{{ $t('user_center.robot_day_profit') }}(USDT)</span>
          <div style="font-family: DINPro-Medium, DINPro;">
            <span style="color: #52C41A;font-size: 30px;">+{{ (add_day * 1) | roundTwoFilter }}</span>
            <span style="font-size: 15px;color: #72777E;opacity:.7"> ≈￥{{ (add_day * 1 * rate) | roundTwoFilter }}</span>
          </div>
        </div>
        <div style="margin-top: 10px;width: 100%">
          <div style="width: 60%">
            <span style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;color: #72777E">{{ $t('user_center.robot_profit_total') }}(USDT)</span>
            <div style="margin-top: 2px">
              <span style="font-size: 20px;color: #000613">{{ (userEarnInfoAll.profit * 1)| roundTwoFilter }}</span>
              <span style="color: #000613;font-size: 12px;margin-left: 5px">≈</span>
              <span style="font-size: 12px;color: #000613;opacity:.7"> ¥{{ (userEarnInfoAll.profit * rate) | roundTwoFilter }}</span>
            </div>
          </div>
          <div style="margin-left: 30px;width: 30%;">
            <span style="display:block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;color:#72777E">{{ $t('user_center.add_up_rate_total') }}</span>
            <span style="display:block;font-size: 20px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #000613;margin-top: 2px">{{ (userEarnInfoAll.yield * 100) | roundTwoFilter }}%</span>
          </div>
        </div>
        <div style="position:absolute;right: 14px; top: 20px;margin-top: 0">
<!--          <img style="margin-right: 20px" src="../../assets/img/user/profit_detail_m_grey.svg" alt="" @click="gotoProfitDetails">-->
          <el-popover
              class="profit_down"
              placement="bottom"
              trigger="click">
            <div style="padding: 5px; text-align: center">
              <span style="display: block; font-size: 16px">扫码下载App查看</span>
              <img style="width: 150px; height: 150px;" src="../../assets/img/home/download_code.png">
              <div style="height: 28px;background: #AB54DB;border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer" @click="download_app()">
                <span style="font-size: 14px; color: #fefefe">更多下载</span>
                <img style="width: 10px; height: 12px;display: inline-block" src="../../assets/img/download/profit_down_icon.png">
              </div>
            </div>
            <img slot="reference" style="margin-right: 20px" src="../../assets/img/user/profit_detail_m_grey.svg" alt="">
<!--            <div slot="reference" style="display: inline-block; text-align: center;margin-right: 50px">-->
<!--              <img src="../../assets/img/user/profit_detail.svg" alt="">-->
<!--              <span style="display:block;color: #72777E; margin-top: 5px">{{ $t('user_center.profit_detail') }}</span>-->
<!--            </div>-->
          </el-popover>
          <img src="../../assets/img/user/share_profit_m_grey.svg" alt="" @click="shareData">
        </div>
      </div>
      <div v-if="smShow" style="width: 100%;margin-bottom: 0">
<!--        <div style="margin-top: 20px;width: 50%;display: inline-block;vertical-align: top">-->
<!--          <div style="display:inline-block;margin-bottom: 6px;font-size: 14px">{{ $t('user_center.month_average_rate') }}</div>-->
<!--          <span style="display:inline-block;color: #52C41A;font-size: 14px"> {{ (userEarnInfoAll.avg_yield * 100).toFixed(2) }}%</span>-->
<!--        </div>-->
        <div style="margin-top: 20px;width: 50%;display: inline-block;vertical-align: top">

        </div>
        <div style="margin-top: 20px;width: 50%;display: inline-block;text-align: right">
          <el-popover
              class="profit_down"
              placement="bottom"
              trigger="click">
            <div style="padding: 5px; text-align: center">
              <span style="display: block; font-size: 16px">扫码下载App查看</span>
              <img style="width: 150px; height: 150px;" src="../../assets/img/home/download_code.png">
              <div style="height: 28px;background: #AB54DB;border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer" @click="download_app()">
                <span style="font-size: 14px; color: #fefefe">更多下载</span>
                <img style="width: 10px; height: 12px;display: inline-block" src="../../assets/img/download/profit_down_icon.png">
              </div>
            </div>
            <div slot="reference">
              <div style="display:inline-block;margin-bottom: 6px;font-size: 14px">{{ $t('user_center.total_earn_rank') }}</div>
              <span style="display:inline-block;font-size: 14px">{{ !userEarnInfoRank.rankNum || userEarnInfoRank.rankNum>450? $t('user_center.no_listed'):userEarnInfoRank.rankNum }}</span><span><i @click="$router.push('/earn_rank')" style="font-size: 14px;cursor: pointer" class="el-icon-d-arrow-right"></i></span>
            </div>
          </el-popover>
<!--          <span @click="$router.push('/earn_rank')" style="display:inline-block;font-size: 14px">{{ userEarnInfoRank.rankNum }}</span><span><i @click="$router.push('/earn_rank')" style="font-size: 14px;cursor: pointer" class="el-icon-d-arrow-right"></i></span>-->

        </div>
      </div>

<!--      累计收益趋势-->
      <div class="trend" v-if="lgShow" style="margin: 0">
<!--        <div class="trend-title">累计收益趋势</div>-->
        <div id="trend-chart" style="width: 100%;height:300px;"></div>
      </div>
<!--      累计收益排行-->
      <div class="rank" v-if="lgShow" style="vertical-align: top;position: relative;margin: 0">
        <div class="rank-title" style="display: inline-block;">{{$t('user_center.total_earn_rank_title')}}
          <span v-if="!userEarnInfoRank.rankNum || userEarnInfoRank.rankNum>450">{{$t('user_center.no_listed')}}</span>
          <span v-else-if="userEarnInfoRank.rankNum>3||userEarnInfoRank.rankNum<=450">{{userEarnInfoRank.rankNum}}</span>
          <span v-else style="color: #E3B936;">NO.{{userEarnInfoRank.rankNum}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span>
          )</div>
        <el-popover
            class="profit_down"
            placement="bottom"
            trigger="click">
          <div style="padding: 10px; text-align: center">
            <span style="display: block; font-size: 16px">扫码下载App查看</span>
            <img style="width: 125px; height: 125px;" src="../../assets/img/home/download_code.png">
            <div style="height: 28px;background: #AB54DB;border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer" @click="download_app()">
              <span style="font-size: 14px; color: #fefefe">更多下载</span>
              <img style="width: 10px; height: 12px;display: inline-block" src="../../assets/img/download/profit_down_icon.png">
            </div>
          </div>
          <div slot="reference" class="rank-title" style=" cursor: pointer;display: inline-block;vertical-align: top;margin: 0;position: absolute;right: 0">{{$t('user_center.else_list')}}>></div>
        </el-popover>
        <div id="rank-chart" style="width: 100%;height:260px;">
          <div class="rank-add" v-for="(item,index) in profitTop10">
            <img v-if="index===0" src="../../assets/img/user/no1.svg" alt="">
            <img v-if="index===1" src="../../assets/img/user/no2.svg" alt="">
            <img v-if="index===2" src="../../assets/img/user/no3.svg" alt="">
            <div v-if="index>2" style="width: 18px;font-size: 12px;font-weight: 600;color: #000613;">{{index+1}}</div>
            <div style="margin-left: 10px">
<!--              <img style="width: 30px;height: 30px" v-if="item.user_face==null" src="../../assets/img/avatar.png" alt="">-->
<!--              <img style="width: 30px;height: 30px" v-else :src="item.user_face" alt="">-->
              <pop_image style="" v-if="item.user_face==null" :img-url="''" :placement="'right'" alt=""/>
              <pop_image style="" v-else :img-url="item.user_face" :placement="'right'" alt=""/>
            </div>
            <span class="name" style="text-align: left">
              <span style="display:block;">{{item.mobile}}</span>
              <span style="display: block;text-align: left">{{item.name}}</span>
            </span>
<!--            <img @click="$router.push('/grant_member')" v-if="item.vip_type===3" style="cursor: pointer" src="../../assets/img/ssvip.svg" alt="">-->
<!--            <img @click="$router.push('/grant_member')" v-if="item.vip_type===2" style="cursor: pointer" src="../../assets/img/SVIP.svg" alt="">-->
<!--            <img @click="$router.push('/grant_member')" v-if="item.vip_type===1" style="cursor: pointer" src="../../assets/img/VIP.svg" alt="">-->
            <span v-if="index<3" class="profit" style="color: #E3B936">{{(item.profit*1) | roundTwoFilter}}</span>
            <span v-else class="profit">{{(item.profit*1) | roundTwoFilter}}</span>
          </div>
        </div>
      </div>
      <div v-if="!this.$util._isMobile()" style="position: absolute;font-size:14px;right: 20px;top: 76px;cursor: pointer">
        <el-popover
            class="profit_down"
            placement="bottom"
            trigger="click">
          <div style="padding: 10px; text-align: center">
            <span style="display: block; font-size: 16px">扫码下载App查看</span>
            <img style="width: 125px; height: 125px;" src="../../assets/img/home/download_code.png">
            <div style="height: 28px;background: #AB54DB;border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer" @click="download_app()">
              <span style="font-size: 14px; color: #fefefe">更多下载</span>
              <img style="width: 10px; height: 12px;display: inline-block" src="../../assets/img/download/profit_down_icon.png">
            </div>
          </div>
          <div slot="reference" style="display: inline-block; text-align: center;margin-right: 50px">
            <img src="../../assets/img/user/profit_detail.svg" alt="">
            <span style="display:block;color: #72777E; margin-top: 5px">{{ $t('user_center.profit_detail') }}</span>
          </div>
        </el-popover>

<!--        <div style="display: inline-block; text-align: center;margin-right: 50px" @click="gotoProfitDetails">-->
<!--          <img src="../../assets/img/user/profit_detail.svg" alt="">-->
<!--          <span style="display:block;color: #72777E; margin-top: 5px">{{ $t('user_center.profit_detail') }}</span>-->
<!--        </div>-->
        <div style="display: inline-block; text-align: center" @click="shareData">
          <img src="../../assets/img/share/share_new1.svg" alt="">
          <span style="display:block;color: #72777E; margin-top: 5px">{{ $t('user_center.share_profit') }}</span>
        </div>

      </div>
    </el-card>

<!--  收益统计-->
    <el-card class="everyday-earn" shadow="hover" id="everyday-earn" ref="everyday-earn">
      <div class="everyday-top">
        <div class="title" style="display: inline-flex;align-items: center">{{$t('user_center.earn_statistics')}} <img @click="ClickTipDialogVisible=true" style=";margin-left: 10px" v-if="smShow" src="../../assets/img/robot/robot_strategy/explain.svg" alt=""></div>
        <div class="title-sub" v-if="lgShow" style="line-height: 20.8px;height: 20.8px;vertical-align: top;cursor: pointer;margin-left: 0"> <tooltip :content="$t('user_center.everyday_earn_tip')"></tooltip></div>
        <el-checkbox v-if="!this.$util._isMobile()" style="margin-bottom: 10px;color: #72777E;margin-left: 20px" v-model="showNoProfit" @change="hideNoProfit">{{ $t('user_center.hide_no_profit') }}</el-checkbox>
        <div class="tabs" :class="(language && language == 'English')?'tabs1':'tabs'" >
          <div id="day" @click="handleSelectDate('day')">{{$t('user_center.today')}}</div>
          <div id="yesterday" @click="handleSelectDate('yesterday')">{{$t('user_center.yesterday')}}</div>
          <div id="week" @click="handleSelectDate('week')">{{$t('user_center.week')}}</div>
          <div id="month" @click="handleSelectDate('month')">{{$t('user_center.month')}}</div>
          <div id="year" @click="handleSelectDate('year')">{{$t('user_center.year')}}</div>
          <div id="lastYear" @click="handleSelectDate('lastYear')">{{$t('user_center.all')}}</div>
        </div>
      </div>
      <el-checkbox v-if="this.$util._isMobile()" style="margin-bottom: 10px;color: #72777E" v-model="showNoProfit" @change="hideNoProfit">{{ $t('user_center.hide_no_profit') }}</el-checkbox>
      <el-table
          v-loading="getEverydayProfitLoading"
          border
          :header-cell-style="{background:'rgba(234, 236, 239, 0.4)'}"
          :data="showNoProfit?tableDataNoProfit:tableData"
          :size="isMini"
          style="width: 100%">
        <el-table-column
            align="center"
            prop="product_name"
            :label="$t('user_center.goods_type')">
        </el-table-column>
        <el-table-column
            v-if="isBilling"
            align="center"
            prop="trade_count"
            :label="$t('user_center.transaction_order_num')">
        </el-table-column>
        <el-table-column
            align="center"
            prop="profit"
            :label="$t('user_center.earn_money')+'(USDT)'">
          <template slot-scope="scope">
            {{ (scope.row.profit*1) | roundTwoFilter }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="profit"
            :label="$t('user_center.earn_money')+'(元)'">
          <template slot-scope="scope">
            {{ (scope.row.profit*rate) | roundTwoFilter }}
          </template>
        </el-table-column>
      </el-table>
<!--      <div v-if="smShow" class="title-sub" style="margin:  12px 0 5px 0;font-size: 12px;font-weight: 400;color: #72777E;"></div>-->
    </el-card>
    <!--  收益查询-->
    <el-card class="earn-query" id="earn-query" shadow="hover" ref="earn-query" style="position: relative">
      <div class="earn-query-top">
        <div class="title">{{$t('user_center.earn_query')}}</div>
        <div class="date-picker">
          <el-date-picker
              class="date-picker-select"
              size="small"
              :editable="false"
              v-model="dateValue"
              value-format="yyyy-MM"
              @change="handleDatePicker"
              :picker-options="pickerOptions"
              type="month"
              :placeholder="$t('user_center.select_month')">
          </el-date-picker>
        </div>
      </div>
      <div class="tip-add" v-show="isExistData">
        <span v-show="currentMonth">{{ $t('user_center.today_earn') }} </span><span v-show="currentMonth">{{(earnQuery.profit[earnQuery.profit.length-1]*1) | roundTwoFilter}}USDT</span>
        <span v-show="currentMonth">{{ $t('user_center.today_earning_rate') }} : </span><span v-show="currentMonth">{{(earnQuery.day_yield[earnQuery.day_yield.length-1]*100) | roundTwoFilter}}%</span>
        <div class="this-month">
          <span>{{ $t('user_center.income_month') }} : </span><span>{{(currentMonthEarn*1) | roundTwoFilter}}USDT</span>
          <span>{{ $t('user_center.current_month_yield') }} : </span><span>{{(earnQuery.month_yield[earnQuery.month_yield.length-1]*100) | roundTwoFilter}}%</span>
        </div>
      </div>
      <div v-show="isExistData" id="earn-query-chart" style="width: 100%;height:560px;margin-top: 21px"></div>
      <el-empty v-show="!isExistData" :description="$t('robot.no_data')"></el-empty>
  <button v-show="saveSelectMonth==null || !(this.saveSelectMonth.year<=2021 && this.saveSelectMonth.month==11)" @click="cutQuery('last')" type="button" class="arrows-btn arrows-btn-left"><i class="el-icon-arrow-left arrows-btn-i"></i></button>
  <button v-show="!(saveSelectMonth==null||(saveSelectMonth.month==new Date().getMonth()+1 && saveSelectMonth.year>=new Date().getFullYear()))" @click="cutQuery('next')" type="button" class="arrows-btn arrows-btn-right"><i class="el-icon-arrow-right arrows-btn-i"></i></button>
    </el-card>
    <Bind_email></Bind_email>

    <el-dialog
        :show-close="false"
        width="280px"
        class="tipDialog"
        :visible.sync="ClickTipDialogVisible">
      <span style="padding: 30px 20px;display: block;line-height: 24px">{{ $t('user_center.everyday_earn_tip') }}</span>
      <el-button style="width: 100%"  @click="ClickTipDialogVisible = false">{{ $t('user_center.sure') }}</el-button>
    </el-dialog>

    <share_earn ref="share_earn" :today_earn="add_day" :shareDate="shareDate" :userEarnInfoAll="userEarnInfoAll"></share_earn>

  </div>
</template>

<script>
import Bind_email from "../../components/Bind_email";
import share_earn from "./share_earn";
import {
  getAllProfitEchart,
  getEverydayProfit,
  getMonthProfitEchart,
  getMyRank,
  getProfitInfo, getProfitTop10, getUSDTRateByCode, getUserProfitLine
} from "../../server/user";
import {getUserProfitByWeekTop10} from "../../server/robot"
import isScreenSize from "../../utils/validate";
import {add, multiply} from "../../plugin/math";
import {screenSize} from "../../utils/tools";

import tooltip from "../common/tooltip";
import {getQrCode} from "../../server/rebate";
import Pop_image from "../common/pop_image";
export default {
  name: "User_center",
  data(){
    return{
      isMini:'mini',
      lgShow:false,
      smShow:false,
      tableData: [],
      tableDataNoProfit: [],
      dateValue:'',
      userEarnInfoAll:{
        avg_yield: 0,
        profit: 0,
        yield: 0
      },
      userEarnInfoRank:{
        profit: 0,
        rankNum: 0,
        user_id: 0,
      },
      //收益趋势图表
      trendProfit:{
        trend_profit_month:[],
        trend_profit:[],
        trend_yield:[]
      },
      //收益查询图表
      currentMonth:true,
      pickerOptions:{
        disabledDate(time){
          return (time.valueOf()<(new Date('2021-11').valueOf()))||time.valueOf()>Date.now() || time.valueOf() < new Date(JSON.parse(localStorage.getItem('userInfo')).create_time).valueOf()
        }
      },
      earnQuery:{
        profit_day:[],
        profit:[],
        month_yield:[],
        day_yield:[]
      },
      distanceRight:isScreenSize(500) ? '6%' :0, //收益查询legend距离右端距离
      profitTop10:[],
      isExistData:true,
    //  汇率
      rate:6.4,
    //  是否显示开单量
      isBilling:true,
    //  今日收益
      add_day:'',
    //  本月收益
      currentMonthEarn:'',
      ClickTipDialogVisible:false,  //提示
      getEverydayProfitLoading:false,
      saveSelectMonth:null,
      language: '',
      showNoProfit: false,
      noEdit: false,
      shareDate: ''
    }
  },
  components:{
    Pop_image,
    Bind_email,
    tooltip,
    share_earn
  },
  created() {
    this.changeScreenSize()
    this.getUSDTRateByCode()
    this.getUserEarnInfo()
    this.getEverydayProfit('1')
    this.getProfitTop10()
  },
  mounted() {
    // this.judgeSlide()
    this.language = localStorage.getItem('language')
    if (localStorage.getItem('showNoProfit')){
      this.showNoProfit = JSON.parse(localStorage.getItem('showNoProfit'))
    }
    this.dateValue=new Date().getFullYear()+'-'+(new Date().getMonth()+1)
    this.getProfitInfo()
    const param={
      year:new Date().getFullYear(),
      month:(new Date().getMonth()+1)
    }
    this.getEarnQueryInfo(param)
    if (!this.lgShow) return
    this.addUpEarn()
  },
  methods: {
    async getUSDTRateByCode(){
      const {data}= await getUSDTRateByCode()
      this.rate=data
    },
    //获取收益信息    //获取排行
    async getUserEarnInfo(){
      const userEarnInfoAll=await getProfitInfo()
      this.userEarnInfoAll=userEarnInfoAll.data
      const myRank=await getMyRank()
      if (!myRank) return
      if (myRank.data===null) return
        this.userEarnInfoRank=myRank.data
    },
    //  累计收益趋势
    async getProfitInfo(){
      const res=await getProfitInfo()
      const trend=await getAllProfitEchart()
      const {trend_profit_month,trend_profit,trend_yield}=this.trendProfit
      trend.data.map(item=>{
        trend_profit_month.push(item.profit_month)
        trend_profit.push(item.profit)
        trend_yield.push(item.yield)
      })
      if (!this.lgShow) return
      // this.$nextTick(()=>{
        this.drawLineTrend()
      // })
    },
    //获取累计收益排行(获取总排行榜前10)
    async getProfitTop10(){
      // const res=await getProfitTop10()
      // if (!res) return
      // this.profitTop10=res.data
      this.profitTop10 = []
      getUserProfitByWeekTop10(0, 2, 1).then(res =>{
        if(!res) return
        if(res.success){
          var list = res.data
          for (var i = 0; i < list.length; i++) {
            if(i<10){
              list[i].profit = list[i].trade_profit
              this.profitTop10.push(res.data[i])
            }
          }
        } else {
          this.$message.warning(res.message)
        }
      })

    },
    handleDatePicker(e){
      if (e==null) return this.getEarnQueryInfo()
      if (new Date(new Date().getFullYear()+'-'+(new Date().getMonth()+1)).valueOf()>new Date(e).valueOf()) this.currentMonth = false
      else this.currentMonth=true
      const param={
        year:e.slice(0,e.indexOf('-')),
        month:e.slice(e.indexOf('-')+1) >9 ? e.slice(e.indexOf('-')+1) : e.slice(e.indexOf('-')+2)
      }
      this.getEarnQueryInfo(param)
    },
    cutQuery(type){
      if (type=='last'){
        if (this.saveSelectMonth==null){
          const saveSelectMonth={}
          saveSelectMonth.year= new Date().getFullYear()
          saveSelectMonth.month= new Date().getMonth()
          this.saveSelectMonth=saveSelectMonth
          this.getEarnQueryInfo(this.saveSelectMonth)
        } else {
          if ((this.saveSelectMonth.year<=2021 && this.saveSelectMonth.month==11) || new Date(this.saveSelectMonth.year+'-'+this.saveSelectMonth.month).valueOf() < new Date(JSON.parse(localStorage.getItem('userInfo')).create_time).valueOf()) return this.$message.warning('没有上一个月的纪录 !')
          if (this.saveSelectMonth.month==1) {
            this.saveSelectMonth.year--
            this.saveSelectMonth.month=12
          }
          else this.saveSelectMonth.month--
          this.getEarnQueryInfo(this.saveSelectMonth)
        }
      } else {
        if (this.saveSelectMonth==null||(this.saveSelectMonth.month==new Date().getMonth()+1 && this.saveSelectMonth.year>=new Date().getFullYear())) return this.$message.warning('已经是最后一个月了 !')
        if (this.saveSelectMonth==null){
          let saveSelectMonth={}
          saveSelectMonth.year= new Date().getFullYear()
          saveSelectMonth.month= new Date().getMonth()+1
          if(saveSelectMonth.month==12){
            saveSelectMonth=1;
            saveSelectMonth.year++
          }
          this.saveSelectMonth=saveSelectMonth
          this.getEarnQueryInfo(this.saveSelectMonth)
        } else {
          if (this.saveSelectMonth.month==12) {
            this.saveSelectMonth.year++
            this.saveSelectMonth.month=1
          }
          else this.saveSelectMonth.month++
          this.getEarnQueryInfo(this.saveSelectMonth)
        }
      }
      var date = (this.saveSelectMonth.year) +'-'+ this.saveSelectMonth.month
      if (new Date(new Date().getFullYear()+'-'+(new Date().getMonth()+1)).valueOf()>new Date(date).valueOf()) this.currentMonth = false
      else this.currentMonth=true
    },
    // judgeSlide(){
    //   var startX=0
    //   document.getElementById('earn-query-chart').addEventListener('touchstart',e=>{
    //     e.preventDefault()
    //     startX = e.changedTouches[0].pageX
    //   })
    //   document.getElementById('earn-query-chart').addEventListener('touchend',e=>{
    //     e.preventDefault()
    //     let moveEndX  = e.changedTouches[0].pageX
    //     if (startX-moveEndX>80) this.cutQuery('next')
    //     if (startX-moveEndX<-80) this.cutQuery('last')
    //   })
    // },
    //获取收益查询图表信息
    async getEarnQueryInfo(param){
      this.saveSelectMonth=param
      if (param!=null){
        this.dateValue=new Date(param.year+'-'+(param.month<10?('0'+param.month):param.month))
      }
      this.earnQuery={
        profit_day:[],
        profit:[],
        month_yield:[],
        day_yield:[]
      }
      const earnQuery=await getMonthProfitEchart(param)
      if(!earnQuery) return
      const {profit_day, profit, month_yield, day_yield}=this.earnQuery
      this.isExistData=false
      if (earnQuery.data.length===0) return
      this.currentMonthEarn=0
      earnQuery.data.map(item=>{
        this.isExistData=true
        profit_day.push(item.profit_day)
        profit.push(item.profit)
        month_yield.push(item.month_yield)
        day_yield.push(item.day_yield)
        this.currentMonthEarn=Number(this.currentMonthEarn)+item.profit
      })
      // console.log(document.getElementById('earn-query-chart').clientWidth);
      this.$nextTick(()=>{
        // console.log(document.getElementById('earn-query-chart').clientWidth);
        this.drawLineQuery()
      })
    },
    drawLineTrend() {
      //累计收益趋势图表
      var that = this
      let trendChart = this.$echarts.init(document.getElementById('trend-chart'))
      trendChart.setOption({
        legend: {
          data: [this.$t('user_center.month_earn'), this.$t('user_center.month_earn_rate')],
          itemHeight: 7, //修改icon图形大小
          textStyle: {
            fontSize: 12,
            color: '#8E9298'
          },
          right:'10%'
        },
        title:{
          text:this.$t('user_center.month_earn_statistics'),
          textStyle:{
            color: '#72777E',
            fontSize:14,
            fontWeight:400
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            },
            label:{
              formatter:function (params) {
                if (params.axisIndex===0&&params.axisDimension!=='x') {
                  params.value =  that.$options.filters['roundTwoFilter'](params.value?parseFloat(params.value):0)
                }
                if (params.axisIndex===1&&params.axisDimension!=='x') params.value=that.$options.filters['roundTwoFilter'](params.value*100)+'%'
                return  params.value;
              }
            }
          },
          formatter:params=>{
            var relVal = `<span style="color: #1E2329;font-weight: 600">${params[0].name}</span>`
            for (var i = 0, l = params.length; i < l; i++) {
              if (i===0) relVal += '<br/>' + `<span style="display: inline-block;margin-top: 6px">${params[i].marker + params[i].seriesName + ' : ' + that.$options.filters['roundTwoFilter'](params[i].value)+'USDT'}</span>`
              else relVal += '<br/>' + `<span style="display: inline-block;margin-top: 4px">${params[i].marker + params[i].seriesName + ' : ' + that.$options.filters['roundTwoFilter'](params[i].value*100) + '%'}</span>`
            }
            return relVal;
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.trendProfit.trend_profit_month.map(item=>{
            return item.replace(' ','\n')
          })
        },
        yAxis: [{
          type: 'value',
          show:true,
          axisLabel:{
            formatter: '{value}'
          },
          // data:[120, 200, 150, 180, 160, 200, 350]
        },
        {
          type: 'value',
          // show: false,
          min: 0,
          // max: 1,
          // interval: 0.1,
          // axisLabel: {formatter: '{value}'}
          axisLabel: {
            formatter: value=>{
              return multiply(value,100)+'%'
            }
          }
      }],
        series: [
          {
            name: this.$t('user_center.month_earn'),
            data: this.trendProfit.trend_profit,
            type: 'line',
            color:'#AB54DB',
            // symbol:'none',
          endLabel: {
            fontWeight: "bold",
            color: "#AB54DB",
            fontSize: 12,
            show: true,
                formatter:(params)=> {
              // return this.trendProfit.trend_profit.slice(-1)[0].toFixed(2)+'USDT ('+(params.value*100).toFixed(2)+'%)';
              return this.$options.filters['roundTwoFilter'](params.value)+'USDT';
            },
            // position: 'top'
            position: 'insideRight',//标签的位置
            offset: [-40,-10],  //标签文字的偏移，此处表示向上偏移40
      },
            areaStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#AB54DB'
                  },
                  {
                    offset: 1,
                    color: 'white'
                  }
                ]
              },
              opacity: 0.3
            }
          },
          {
            name: this.$t('user_center.month_earn_rate'),
            data: this.trendProfit.trend_yield,
            type: 'line',
            color:'#E3B936',
            // symbol:'none',
            yAxisIndex: 1,
            endLabel: {
              fontWeight: "bold",
              color: "#E3B936",
              fontSize: 12,
              show: true,
              formatter:(params)=> {
                // return this.trendProfit.trend_profit.slice(-1)[0].toFixed(2)+'USDT ('+(params.value*100).toFixed(2)+'%)';
                return this.$options.filters['roundTwoFilter'](params.value*100)+'%';
              },
              // position: 'top'
              position: 'insideRight',//标签的位置
              offset: [-40,-10],  //标签文字的偏移，此处表示向上偏移40
            },
          }
        ]
      });

        window.addEventListener('resize',()=> {
        trendChart.resize()
      })
    },
    //收益查询图表
    drawLineQuery(){
      var that = this
      let profitMax=Math.max(...this.earnQuery.profit)
      // this.$echarts.dispose(document.getElementById('earn-query-chart'))
      let queryChart = this.$echarts.init(document.getElementById('earn-query-chart'))
      queryChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            },
            label:{
              formatter:function (params) {
                if (params.axisIndex===0&&params.axisDimension!=='x') {
                  params.value = that.$options.filters['roundTwoFilter'](Number(params.value))
                }
                if (params.axisIndex===1&&params.axisDimension!=='x') params.value=that.$options.filters['roundTwoFilter'](params.value*100)+'%'
                return  params.value;
              }
            }
          },
          formatter:params=>{
              var relVal = `<span style="color: #1E2329;font-weight: 600">${params[0].name}</span>`
            for (var i = 0, l = params.length; i < l; i++) {
              if (i===0) relVal += '<br/>' + `<span style="display: inline-block;margin-top: 6px">${params[i].marker + params[i].seriesName.slice(0,params[i].seriesName.indexOf('（')) + ' : ' + this.$options.filters['roundTwoFilter'](params[i].value)+'USDT'}</span>`
              else relVal += '<br/>' + `<span style="display: inline-block;margin-top: 4px">${params[i].marker + params[i].seriesName.slice(0,params[i].seriesName.indexOf('(')) + ' : ' + this.$options.filters['roundTwoFilter'](params[i].value*100) + '%'}</span>`
              }
              return relVal;
          },
          textStyle:{
            fontSize : 14,
            height: 0,
          }
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          data: [this.$t('user_center.earn')+'（USDT）', this.$t('user_center.day_earn_rate')+'(%)', this.$t('user_center.month_earn_rate')+'(%)'],
          itemHeight: 7, //修改icon图形大小
          textStyle: {
            fontSize: 12,
            color: '#8E9298'
          },
          right:this.distanceRight
        },
        xAxis: [
          {
            type: 'category',
            data:this.earnQuery.profit_day.map(item=>{
              return item.slice(0,item.indexOf(' '))
            }),
            axisTick: {
              show: true,
              alignWithLabel: true
            },
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: 'Precipitation',
            min: 0,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            // name: 'Temperature',
            min: 0,
            axisLabel: {
              formatter: value=>{
                return multiply(value,100)+'%'
              }
            }
          },
        ],
        series: [
          {
            name: this.$t('user_center.earn')+'（USDT）',
            type: 'bar',
            barWidth:screenSize(900) ? 10 :5,
            color:'#AB54DB',
            data: this.earnQuery.profit,
            label:{
              show:true,
              position:'top',
              color:'#AB54DB',
              fontWeight: "bold",
              fontSize: 14,
              formatter:item=>{
                // console.log(item);
                if (item.data==profitMax) {
                  return this.$options.filters['roundTwoFilter'](item.data)+'USDT'
                }
                return ''
              }
            },
            // markPoint:{
            //   data: [
            //     {
            //       type: 'max',
            //       name: '最大值',
            //     },
            //   ],
            //   label:{
            //     color:'white',
            //     formatter:item=>{
            //       return profitMax.toFixed(2)
            //     }
            //   }
            // }
          },
          {
            name: this.$t('user_center.day_earn_rate')+'(%)',
            type: 'line',
            // symbol:'none',
            color:'#E3B936',
            lineStyle:{
              color:'#E3B936',
              width:2
            },
            yAxisIndex: 1,
            data: this.earnQuery.day_yield
          },
          {
            name: this.$t('user_center.month_earn_rate')+'(%)',
            type: 'line',
            // symbol:'none',
            color:'#52C41A',
            yAxisIndex: 1,
            data: this.earnQuery.month_yield,
            endLabel: {
              show: true,
              formatter:(params)=> {
                return this.$options.filters['roundTwoFilter'](params.value*100)+'%';
              },
              // position: 'top'
              position: 'insideRight',//标签的位置
              offset: [-30,-16],  //标签文字的偏移，此处表示向上偏移40
              fontWeight: "bold",
              color: "#52C41A",
              fontSize: 14
            },
          }
        ],
        grid: this.$util._isMobile()?{
          top: '10%',
          left: '10%',
          right: '15%',
          bottom: '10%',
          containLabel: false
        }:{}
      });
      window.addEventListener('resize',()=> {
        queryChart.resize()
      })
    },
    //每日收益 选择
    async handleSelectDate(e){
      if (this.noEdit != false) {
      }else{
        this.noEdit = true
        this.isBilling=true
        if (e==='lastYear') this.isBilling=false
        const tab=document.querySelectorAll('.tabs div')
        Object.keys(tab).map(item=>{
          tab[item].style.color='#72777E'
        })
        document.getElementById(e).style.color='#AB54DB'
        if (e==='day') e=1
        else if (e==='week') e=2
        else if(e==='yesterday') e=5
        else if (e==='month') e=3
        else if (e==='year') e=4
        else if (e==='lastYear') e=0
        this.tableDataNoProfit = []
        await this.getEverydayProfit(e)
      }
    },
    //获取每日收益的数据
    async getEverydayProfit(time='1'){
      var that = this
      this.getEverydayProfitLoading=true
      const res=await getEverydayProfit(time)
      if(!res) return
      if (res.success){
        this.noEdit = false
        if (res.data){
          var data = res.data
          this.getEverydayProfitLoading=false
          this.tableData=data
          this.tableDataNoProfit = []
          this.tableDataNoProfit = this.tableDataNoProfit.concat(this.tableData)
          if (time==1) {
            this.add_day = 0
            data.map(item=>{
              this.add_day=Number(this.add_day)+item.profit
            })
          }
          if (this.showNoProfit){
            this.hideNoProfitList()
          }
        }
      }else {
        this.noEdit = false
        this.$message.error(res.message)
      }


    },
    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=1300) {
          this.lgShow = true
          this.isMini='medium'
          this.smShow=false
          // this.$nextTick(()=>{
          //   this.drawLineTrend()
          //   this.drawLineTrend();
          // })
          return
        }
        this.smShow=true
        this.lgShow=false
        this.isMini='mini'
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=1300) {
        this.isMini = 'medium';
        return this.lgShow = true;
      }
      this.smShow=true
      this.isMini='mini'
    },
  //  累計收益趨勢的走勢圖
    async addUpEarn(){
      const {data:res}=await getUserProfitLine()
      // console.log(res);
      const monthX=[]; const profitY=[]
      res.map(item=>{
        monthX.push(item.profit_month)
        profitY.push(item.profit)
      })
      var addUpEarnChart = this.$echarts.init(document.getElementById('add-up-earn'));
      addUpEarnChart.setOption( {
        xAxis: {
          type: 'category',
          data: monthX,
          show:false
        },
        yAxis: {
          type: 'value',
          show:false,
          min: 0,
        },
        series: [
          {
            data: profitY,
            type: 'line',
            smooth: true,
            showSymbol: false,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                offset: 0, color: 'rgba(82, 196, 26, 1)' // 0% 处的颜色
              }, {
                offset: 0.5, color: 'rgba(82, 196, 26, 0.5)'
              }, {
                offset: 1, color: 'rgba(82, 196, 26, 0.1)' // 100% 处的颜色
              }])
          }
        ],
        grid: {
          x:0,
          y:0,
          y1:0,
          y2:0
        },
      });
      window.addEventListener('resize',()=> {
        addUpEarnChart.resize()
      })
    },
    async shareData(){
      this.$refs.share_earn.qr_code_url=window.URL.createObjectURL(await getQrCode())
      this.$refs.share_earn.share_warn_visible_dialog=true
      let date=new Date()
      this.shareDate = date.getFullYear().toString()+'-'+ (date.getMonth()< 9?'0'+(date.getMonth() + 1):(date.getMonth() + 1))+'-'+(date.getDate()<10 ? '0'+date.getDate() :date.getDate() )+ ' ' +(date.getHours()<10?'0'+date.getHours():date.getHours()) +':' +(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
    },
    hideNoProfit(event){
      this.showNoProfit = event
      localStorage.setItem('showNoProfit', JSON.stringify(event))
      if (event){
        this.hideNoProfitList()
      }
    },
    hideNoProfitList(){
      for (let i = 0; i < this.tableDataNoProfit.length; i++) {
        if (!this.tableDataNoProfit[i].profit || this.tableDataNoProfit[i].profit == 0){
          this.tableDataNoProfit.splice(i,1)
          i--
        }
      }
    },
    gotoProfitDetails(){ // 收益详情
      this.$router.push('./profit_details')
    },

    download_app(){
      if (this.$util._isMobile()){
        this.$router.push({name: 'download', query:{showBack: 1}})
      } else {
        this.$router.push({name: 'download', query:{showBack: 1}})
        // this.showcode = true
      }
    },
  },
}
</script>

<style scoped>
/*********         机器人收益        *************/
.robot-earn .el-card__body>div:first-child>div:first-child{
  font-size: 16px;
  color: #1E2329;
  font-weight: 600;
}
.robot-earn .el-card__body>div:first-child div:nth-child(2){
  font-size: 14px;
  color: #72777E;
  margin: 24px 0 10px 0;
}
.robot-earn .el-card__body>div:first-child div:nth-child(3){
  font-size: 30px;
  color: #000613;
  display: inline-block;
  margin:0 36px 7px 0;
  vertical-align: top;
}
.robot-earn .el-card__body>div:first-child div:nth-child(4){
  display: inline-block;
  vertical-align: top;
}
.robot-earn .el-card__body>div:first-child div:nth-child(4) div:first-child{
  font-size: 20px;
  color: #72777E;
  display: inline-block;
}
.robot-earn .el-card__body>div:first-child div:nth-child(4) div:nth-child(2){
  display: inline-block;
  font-size: 20px;
  line-height: 40px;
  margin: 0 0 0 6px;
  color: #000613;
}
.robot-earn .el-card__body>div:first-child div:nth-child(5){
  font-size: 12px;
  color: #72777E;
  margin-bottom: 24px;
}
.robot-earn .el-card__body>div:first-child div:nth-child(5) div:nth-child(1){
  font-size: 12px;
  color: #72777E;
  display: inline-block;
}
.robot-earn .el-card__body>div:first-child div:nth-child(5) div:nth-child(2){
  display: inline-block;
  margin:0  0 0 40px;
  font-size: 12px;
  color: #72777E;
}
.robot-earn .el-card__body .trend,.robot-earn .el-card__body .rank{
  display: inline-block;
  width: 50%;
}
.robot-earn .el-card__body .trend .trend-title,.robot-earn .el-card__body .rank .rank-title{
  font-size: 14px;
  color: #72777E;
  vertical-align: top;
}
/************       每日收益           ************/
.everyday-top{
  margin-bottom: 20px;
  position: relative;
}
.everyday-earn .el-card__body .everyday-top > div{
  display: inline-block;
}
.everyday-earn .title{
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}
.everyday-earn .title-sub{
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-left: 16px;
}
.el-table__body, .el-table__footer, .el-table__header{
  color: #4e71f7 !important;
  border-radius: 20px;
}
.everyday-top .tabs{
  position: absolute;
  right: 0;
  top: 0;
  display: flex !important;
  width: 200px;
  justify-content: space-between;
}
.everyday-top .tabs>div{
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  cursor: pointer;
}
.everyday-top .tabs>div:first-child{
  color: #AB54DB;
}

/*************    收益查询      ***********/
.earn-query .earn-query-top{
  position: relative;
}
.earn-query-top .title{
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}
.earn-query-top>.date-picker{
  position: absolute;
  top: -4px;
  right: 0;
}
/*累计收益排行add*/
#rank-chart{
  margin:0px;
  padding:0px;
  height:200px;
  width: 100%;
  text-align: center;
  /*-moz-column-gap: 12px;*/
  -moz-column-count: 2;
  /*-webkit-column-gap: 12px;*/
  -webkit-column-count: 2;
  column-gap: 60px;
  column-count: 2;
}
.rank-add{
  height: 30px;
  display: flex !important;
  align-items: center;
  padding: 9px 0 6px 0 ;
  margin: 0 !important;
  border-bottom: 1px solid rgba(65, 97, 128, 0.15);
  position: relative;
}
.rank-add img:first-child{
  width: 18px;
  height: 26px;
}
.rank-add span.name{
  font-size: 14px;
  font-weight: 400;
  color: #000613;
  margin: 0 8px 0 2px;
}
.rank-add img:nth-child(3){
  /*width: 16px;*/
  /*height: 16px;*/
}
.rank-add span.profit{
  font-size: 14px;
  font-weight: 600;
  color: #314659;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 1397px){
  #rank-chart{
    column-gap: 15px;
  }
}

.sm-add-profit{
  background: url("../../assets/img/user/profit_bg_new.png") round;
}

.sm-add-profit>div:first-child{
  font-size: 16px;
  font-weight: 500;
  color: #000613;
  margin-top: 16px;
  margin-bottom: 12px;
}
.sm-add-profit>div:nth-child(2){
  display: flex;
  align-items: center;
}
.sm-add-profit>div:nth-child(2)>span:first-child{
  font-size: 30px;
  font-weight: 500;
  color: #E3B936;
}
.sm-add-profit>div:nth-child(2)>span:nth-child(2){
  font-size: 20px;
  font-weight: 400;
  color: #72777E;
  margin: 0 5px;
}
.sm-add-profit>div:nth-child(2)>span:nth-child(3){
  font-size: 20px;
  font-weight: 400;
  color: #000613;
}
.sm-add-profit>div:nth-child(3){
  margin-top: 12px;
  margin-bottom: 32px;
}
.sm-add-profit>div:nth-child(3)>span:first-child{
  font-size: 20px;
  font-weight: 400;
  color: #72777E;
}
.sm-add-profit>div:nth-child(3)>span:nth-child(2){
  font-size: 20px;
  font-weight: 400;
  color: #000613;
}
.sm-add-profit>div:nth-child(4)>span:nth-child(1),.sm-add-profit>div:nth-child(4)>span:nth-child(3){
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
}
.sm-add-profit>div:nth-child(4)>span:nth-child(2),.sm-add-profit>div:nth-child(4)>span:nth-child(4){
  font-size: 16px;
  font-weight: 400;
  color: #000613;
}

.everyday-top .tabs1{
  position: absolute;
  right: 0;
  top: 0;
  display: flex !important;
  width: unset;
  justify-content: space-between;
}

.everyday-top .tabs1>div{
  display: inline-block !important;
  padding: 5px;
}

.this-month {
  display: inline-block;
}

.this-month :nth-child(2n+1){
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.this-month :nth-child(2n){
  font-size: 14px;
  font-weight: 400;
  color: #000613;
  margin-right: 20px;
}


/*修改卡片的小屏边距*/
@media screen and (max-width: 700px){

  .el-card{
    overflow: scroll;
  }
  /deep/ .earn-query .el-card__body {
    padding: 20px 0  !important;
    margin: 0 18px !important;
    border: none !important;
    border-bottom: 1px solid #EAECEF !important;
  }

  .everyday-top .tabs1{
    position: unset;
    display: block !important;
    margin-top: 10px;
    width: unset;
    text-align: right;
  }

  .everyday-top .tabs1>div{
    display: inline-block !important;
    padding: 5px;
  }

}

/*收益查询新增*/
.tip-add{
  position: absolute;
  top: 62px;
  left: 8%;
}
.tip-add>span:nth-child(2n+1){
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.tip-add>span:nth-child(2n){
  font-size: 14px;
  font-weight: 400;
  color: #000613;
  margin-right: 20px;
}
@media screen and (max-width: 830px){
  .tip-add{
    position: relative;
    top: 12px;
    right: 8%;
    left: 2%;
  }
  .tip-add>span:nth-child(2n){
    margin-right: 6px;
  }
  .tip-add>span:nth-child(2n+1){
    font-size: 13px;
    font-weight: 400;
    color: #8E9298;
  }
  .tip-add>span:nth-child(2n){
    font-size: 12px;
    font-weight: 400;
    color: #000613;
    /*margin-right: 20px;*/
  }
  .this-month {
    display: block;
  }
  .this-month :nth-child(2n+1){
    font-size: 13px;
    font-weight: 400;
    color: #8E9298;
  }
  .this-month :nth-child(2n){
    font-size: 12px;
    font-weight: 400;
    color: #000613;
    margin-right: 6px;
  }
}



/*添加的今日收益的動畫*/
.add-day{
  position: absolute;
  right: 0;
  font-size: 18px;
  top: -50px;
  opacity: 0;
  -webkit-animation:animateDay 1.1s ease-in-out 0s 1 normal ;
}
@keyframes animateDay {
  from{
    top: -20px;
    opacity: 1;
  }
  to{
    top: -50px;
    opacity: 0;
  }
}
 >>> .tipDialog  .el-dialog__header{
  padding: 0;
}
 >>> .tipDialog .el-dialog__body{
   padding: 0 !important;
 }
 .tipDialog{
   border-radius: 4px;
   text-align: center;
 }


 .arrows-btn{
   cursor: pointer;
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: 0;
   color: white;
   background: rgba(31,45,61,0.15);
   position: absolute;
   top: 50%;
 }
 .arrows-btn:hover{
   background: rgba(31,45,61,0.5);
 }

.arrows-btn-left{
  left: 3%;
}
.arrows-btn-right{
  right: 3%;
}

.date-picker-select >>>.el-input__inner{
  cursor: pointer;
}
/*@media screen and (max-width: 900px){*/
/*  .arrows-btn{display: none}*/
/*}*/
</style>
