const math = require('mathjs')
// 加法
function add(a, b) {
    return math.format(math.add(math.bignumber(a), math.bignumber(b)))
}

// 减法
function subtract(a, b) {
    return math.format(math.subtract(math.bignumber(a), math.bignumber(b)))
}

// 乘法
function multiply(a, b) {
    return math.format(math.multiply(math.bignumber(a), math.bignumber(b)))
}

// 除法
function divide(a, b) {
    return math.format(math.divide(math.bignumber(a), math.bignumber(b)))
}
export {
    add,
    subtract,
    multiply,
    divide
}

