<template>
  <div>
    <el-dialog
        :visible.sync="isBoundEmail"
        :center="true"
        width="510px">
<!--      <div style="text-align: center">-->
<!--        <img src="../assets/img/pic.svg" alt="">-->
<!--        <div style="font-size: 22px;font-weight: 600;color: rgba(0, 0, 0, 0.85);margin: 24px 0 8px ">请绑定邮箱  开启邮箱登陆</div>-->
<!--        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.45);line-height: 22px">即将关闭手机登陆入口，为不影响后续功能服务请绑定邮箱！</div>-->
<!--      </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="isBoundEmail = false">下次再说</el-button>-->
<!--    <el-button type="primary" @click="toBindEmail">立即绑定</el-button>  text-indent: 2em;-->
<!--  </span>-->

      <div style="color: #1E2329;font-size: 14px">
        <div style="margin-bottom: 20px; font-size: 18px">亲爱的用户：</div>
        <div style="line-height: 20px;word-break: break-word;">OKX版对冲合约交易已正式上线，使用前需至交易所设置交易权限！</div>
<!--        <div style="line-height: 20px;word-break: break-word;text-indent: 2em">对冲合约交易内测版已上线，使用前需至交易所设置交易权限</div>-->
<!--        <div style="line-height: 20px;word-break: break-word">补充燃料费需从其他账户(资金账户、返佣奖金)划转至交易账户。</div>-->
        <div style="text-align: left; margin: 30px 0 5px;">感谢您对UPlus量化的支持！！！</div>
        <div style="text-align: right;line-height: 20px;margin-top: 10px">UPlus量化团队 <br>  2022/06/07  </div>
      </div>
<!--      <div class="btn" @click="$router.push('robot')" style="cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center">立即体验</div>-->
      <div>
<!--        <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center">我知道了</div>-->
        <a style="display: block;cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center" href="/helpContent?titleNum=10&home=home">查看教程</a>
        <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 15px;color: #72777E;text-decoration: underline;text-align: center">下次再说</div>
      </div>
<!--      <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 15px;color: #72777E;text-decoration: underline;text-align: center">我知道了</div>-->
    </el-dialog>


<!--    <el-dialog-->
<!--        :visible.sync="isBoundEmail"-->
<!--        :center="true"-->
<!--        width="600px">-->
<!--      &lt;!&ndash;      <div style="text-align: center">&ndash;&gt;-->
<!--      &lt;!&ndash;        <img src="../assets/img/pic.svg" alt="">&ndash;&gt;-->
<!--      &lt;!&ndash;        <div style="font-size: 22px;font-weight: 600;color: rgba(0, 0, 0, 0.85);margin: 24px 0 8px ">请绑定邮箱  开启邮箱登陆</div>&ndash;&gt;-->
<!--      &lt;!&ndash;        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.45);line-height: 22px">即将关闭手机登陆入口，为不影响后续功能服务请绑定邮箱！</div>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--      &lt;!&ndash;      <span slot="footer" class="dialog-footer">&ndash;&gt;-->
<!--      &lt;!&ndash;    <el-button @click="isBoundEmail = false">下次再说</el-button>&ndash;&gt;-->
<!--      &lt;!&ndash;    <el-button type="primary" @click="toBindEmail">立即绑定</el-button>  text-indent: 2em;&ndash;&gt;-->
<!--      &lt;!&ndash;  </span>&ndash;&gt;-->

<!--      <div style="color: #1E2329;font-size: 14px">-->
<!--        <div style="margin-bottom: 20px; font-size: 18px">亲爱的用户：</div>-->
<!--        <div style="line-height: 20px;word-break: break-word;">为响应当地政府监管政策要求，Felton已于2021年9月25日（UTC+8）停止了中国大陆地区新用户注册，详见:<span style="cursor: pointer; color: #AB54DB" @click="gotoMessage()">《Felton 业务调整通知》</span>。</div>-->
<!--        <div style="line-height: 20px;word-break: break-word;margin-top: 10px">对身份认证为中国大陆地区的存量用户，计划于2022年6月10日（UTC+8）24:00之前，在保证用户资产安全的前提下完成清退，具体清退细则将通过公告、邮件、站内信等方式通知用户（注意：清退细则不通过手机短信通知），最终解释以官方公告为准，谨防诈骗。</div>-->
<!--        <div style="line-height: 20px;word-break: break-word;margin-top: 12px">我们将切实保障用户资产的安全，并坚持合规经营的一贯原则，在全球范围内遵守各属地法律与政策的规定展业!</div>-->
<!--        &lt;!&ndash;        <div style="line-height: 20px;word-break: break-word;text-indent: 2em">对冲合约交易内测版已上线，使用前需至交易所设置交易权限</div>&ndash;&gt;-->
<!--        &lt;!&ndash;        <div style="line-height: 20px;word-break: break-word">补充燃料费需从其他账户(资金账户、返佣奖金)划转至交易账户。</div>&ndash;&gt;-->
<!--        <div style="text-align: right; margin: 30px 0 5px;">感谢您对Felton量化的支持！！！</div>-->
<!--        &lt;!&ndash;        <div style="text-align: right;line-height: 20px;margin-top: 10px">Felton量化团队 <br>  2022/05/25  </div>&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;      <div class="btn" @click="$router.push('robot')" style="cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center">立即体验</div>&ndash;&gt;-->
<!--      <div>-->
<!--        &lt;!&ndash;        <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center">我知道了</div>&ndash;&gt;-->
<!--        &lt;!&ndash;        <a style="display: block;cursor: pointer;margin-top: 10px;width: 100%;height: 44px;background: #AB54DB;border-radius: 4px;font-size: 16px;color: #FFFFFF;line-height: 44px;text-align: center" href="/helpContent?titleNum=9&home=home">查看教程</a>&ndash;&gt;-->
<!--        &lt;!&ndash;        <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 15px;color: #72777E;text-decoration: underline;text-align: center">下次再说</div>&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;      <div class="btn" @click="isBoundEmail=false;" style="cursor: pointer;margin-top: 15px;color: #72777E;text-decoration: underline;text-align: center">我知道了</div>&ndash;&gt;-->
<!--    </el-dialog>-->

  </div>
</template>

<script>
import {getUserInfo} from "../server/rebate";
import {getMemberRemainNum} from "../server/member";

export default {
  name: "Bind_email",
  data(){
    return {
      isBoundEmail:false,
      releaseTime: '2022/6/7 13:00:00', // 上线时间
      showDialog: 1000*60*60*24*7, // 显示7天
      // showDialog: 1000*60*60*3,

      memberRemainInfo: {}
    }
  },
  created() {
    this.getMemberRemainNum()
     // getUserInfo().then(res=>{
     //
     //   // 移除过往缓存过的key
     //   localStorage.removeItem(res.data.id)
     //   localStorage.removeItem(res.data.id + '\'1')
     //   // localStorage.removeItem(res.data.id + '\'1-1')
     //   localStorage.removeItem(res.data.id+'\'1-1-1')
     //
     //
     //   //   this.isBoundEmail = true
     //   //   this.$cookies.set((res.data.id)+'\'1-1-1',false)
     //
     //   if (new Date().getTime() - new Date(this.releaseTime) < this.showDialog){
     //     // 下次修改时把 request 和 center（595行） 里删除 移除缓存的代码
     //     if (localStorage.getItem(res.data.id+'\'1-1')==null) this.isBoundEmail =true // 不清除缓存情况下  只显示一次
     //     localStorage.setItem((res.data.id)+'\'1-1','false')
     //
     //
     //     // if (this.$cookies.get(res.data.id+'\'1-1-1')){  // 每天显示一次
     //     //   this.isBoundEmail = false
     //     // } else {
     //     //   this.$cookies.config(60*3, '')
     //     //   this.isBoundEmail = true
     //     //   this.$cookies.set((res.data.id)+'\'1-1-1',false)
     //     // }
     //   }
     // })
  },
  methods:{
    toBindEmail(){
      this.$router.push('/user/safe')
      this.$store.state.isBindEmail=true
    },
    getMemberRemainNum(){
      getMemberRemainNum().then(res=>{
        if (res.data!=null) this.memberRemainInfo=res.data
        this.memberRemainNumInfo=res.data
        this.memberPrice = this.memberRemainNumInfo.svip_price
        getUserInfo().then(resss=>{

          // 移除过往缓存过的key
          localStorage.removeItem(resss.data.id)
          localStorage.removeItem(resss.data.id + '\'1')
          // localStorage.removeItem(res.data.id + '\'1-1')
          localStorage.removeItem(resss.data.id+'\'1-1-1')

          if (new Date().getTime() - new Date(this.releaseTime) < this.showDialog){
            // 下次修改时把 request 和 center（595行） 里删除 移除缓存的代码
            if (localStorage.getItem(resss.data.id+'\'1-1')==null) this.isBoundEmail =true // 不清除缓存情况下  只显示一次
            localStorage.setItem((resss.data.id)+'\'1-1','false')
          }
        })
      })
    },
    gotoMessage(){
      window.open('http://8.214.96.149:9099/clearUsers/index.html')
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog{
  padding-bottom: 16px;
}

>>> .el-dialog__header ,>>>.el-dialog{
  border-right: 4px;;
  padding: 0 !important;
}
>>> .el-dialog__body{
  background: linear-gradient(180deg, #E2ECFF 0%, #FFFFFF 100%);
  border-radius: 4px;
}
@media screen and (max-width: 900px){
>>>.el-dialog__body {
  padding: 20px !important;
  border-radius: 4px;
}
}
.btn:hover{
  opacity: 0.8;
}
</style>
