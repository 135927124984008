<template>
  <div>
    <!--    推广海报二维码弹框-->
    <el-dialog
        class="spreadDialogVisible"
        :show-close="false"
        :visible.sync="share_warn_visible_dialog"
        width="375px"
        center
        height="672px">
      <div style="background: #FFFFFF;padding: 40px 28px 40px;position: relative">
        <div style="position: absolute;top: 24px;right: 17px;z-index: 100"><img style="width: 16px;height: 16px;cursor: pointer" @click="share_warn_visible_dialog=false" src="../../assets/img/close.svg" alt=""></div>
        <div style="position: absolute;top: 0;left: 0;"><img style="width: 375px" src="../../assets/img/share/grid.svg" alt=""></div>
        <div style="position: relative;">
          <div style="font-size: 14px;font-weight: 400;color: rgba(255, 255, 255, 0.4);line-height: 20px;text-align: center">{{$t('user_center.share_time')}}：{{ shareDate }}</div>
          <div style="text-align: center"><img style="margin: 36px 0 29px 0;width: 268px;height: 196px" src="../../assets/img/share/pic.png" alt=""></div>
          <div style="color: #FFFFFF;font-size: 16px;text-align: center">{{$t('user_center.today_earn')}}</div>
          <div style="font-size: 40px;font-weight: 500;color: #52C41A;margin: 4px 0 24px 0;text-align: center">+{{(today_earn*1).toFixed(2)}} USDT</div>
          <div style="display: flex;justify-content: space-around">
            <div style="display: inline-block">
              <div style="font-size: 14px;color: rgba(255, 255, 255, 0.5);">{{$t('user_center.robot_total_earn')}}</div>
              <div style="font-size: 16px;color: #FFFFFF;margin-top: 8px">{{(userEarnInfoAll.profit*1).toFixed(2)}} USDT</div>
            </div>
            <div style="display: inline-block">
              <div style="font-size: 14px;color: rgba(255, 255, 255, 0.5);">{{$t('user_center.add_up_rate')}}</div>
              <div style="font-size: 16px;color: #FFFFFF;margin-top: 8px">{{(userEarnInfoAll.yield*100).toFixed(2)}} %</div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;margin-top: 50px">
            <div style="display: inline-block;">
<!--              <img src="../../assets/img/Felton.svg" alt="">-->
              <span style="color: #AB54DB; font-size: 26px; font-weight: bold">UPlus</span>
              <div style="color: #FFFFFF;font-size: 13px;margin-top: 8px">{{$t('user_center.share_title')}}</div>
            </div>
            <img style="display: inline-block;width: 56px;height: 56px" :src="qr_code_url" alt="">
          </div>
          <div style="text-align: center"><div id="btn-down" style="display: inline-block;width: 100%;height:40px;line-height: 40px;margin-top:24px;border: 1px solid #AB54DB;font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer;text-align: center"  @click="handleDownload">{{$t('rebate.download_img')}}</div></div>
        </div>
      </div>
    </el-dialog>

    <!--    下载的海报-->
    <div id="capture" style="position: absolute;right: 1000000px;top: 0;width: 375px">
      <div style="  background: linear-gradient(180deg, #171953 0%, #10151D 100%, #10151D 100%);padding: 40px 28px 40px;position: relative">
<!--      <div style="position: absolute;top: 24px;right: 17px;"><img style="width: 16px;height: 16px;cursor: pointer" @click="share_warn_visible_dialog=false" src="../../assets/img/close.svg" alt=""></div>-->
        <div style="position: absolute;top: 0;left: 0;"><img src="../../assets/img/share/grid.svg" alt=""></div>
        <div>
          <div style="font-size: 14px;font-weight: 400;color: rgba(255, 255, 255, 0.4);line-height: 20px;text-align: center">{{$t('user_center.share_time')}}：{{ shareDate }}</div>
          <div style="text-align: center"><img style="margin: 36px 0 29px 0;width: 268px;height: 196px" src="../../assets/img/share/pic.png" alt=""></div>
          <div style="color: #FFFFFF;font-size: 16px;text-align: center">{{$t('user_center.today_earn')}}</div>
          <div style="font-size: 40px;font-weight: 500;color: #52C41A;margin: 4px 0 24px 0;text-align: center">+{{(today_earn*1).toFixed(2)}} USDT</div>
          <div style="display: flex;justify-content: space-around">
            <div style="display: inline-block">
              <div style="font-size: 14px;color: rgba(255, 255, 255, 0.5);">{{$t('user_center.robot_total_earn')}}</div>
              <div style="font-size: 16px;color: #FFFFFF;margin-top: 8px">{{(userEarnInfoAll.profit*1).toFixed(2)}} USDT</div>
            </div>
            <div style="display: inline-block">
              <div style="font-size: 14px;color: rgba(255, 255, 255, 0.5);">{{$t('user_center.add_up_rate')}}</div>
              <div style="font-size: 16px;color: #FFFFFF;margin-top: 8px">{{(userEarnInfoAll.yield*100).toFixed(2)}} %</div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;margin-top: 50px">
            <div style="display: inline-block;">
              <img src="../../assets/img/Felton.svg" alt="">
              <div style="color: #FFFFFF;font-size: 13px;margin-top: 8px">{{$t('user_center.share_title')}}</div>
            </div>
            <img style="display: inline-block;width: 50px;height: 50px" :src="qr_code_url" alt="">
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import {getQrCode} from "../../server/rebate";

export default {
  name: "share_earn",
  data(){
    return {
      share_warn_visible_dialog:false,
      qr_code_url:'',
      currentDate: ''
    }
  },
  props:['userEarnInfoAll','today_earn', 'shareDate'],
  computed:{
    // currentDate(){
    //   let date=new Date()
    //   return date.getFullYear().toString()+'-'+ (date.getMonth()< 9?'0'+(date.getMonth() + 1):(date.getMonth() + 1))+'-'+(date.getDate()<10 ? '0'+date.getDate() :date.getDate() )+ ' ' +(date.getHours()<10?'0'+date.getHours():date.getHours()) +':' +(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
    // },
  },

  created() {
    // currentDate(){
    //     let date=new Date()
    //     this.currentDate = date.getFullYear().toString()+'-'+ (date.getMonth()< 9?'0'+(date.getMonth() + 1):(date.getMonth() + 1))+'-'+(date.getDate()<10 ? '0'+date.getDate() :date.getDate() )+ ' ' +(date.getHours()<10?'0'+date.getHours():date.getHours()) +':' +(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
      // },
  },

  methods:{
    //  下载推广海报
    handleDownload(){
      var userAgent = navigator.userAgent;
      var browser = {
        versions: function () {
          var u = navigator.userAgent, app = navigator.appVersion;
          return {         //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }
      if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          //在微信中打开
          this.$message.warning('请使用浏览器下载 !')
        }
      }

      if(userAgent.indexOf('UCBrowser') > -1 || userAgent.indexOf('Quark') > -1) this.$message.warning('暂不支持uc和夸克浏览器下载 !')
      this.$html2canvas(document.querySelector("#capture")).then(canvas => {
        document.body.appendChild(canvas)
        var url = canvas.toDataURL({format: 'image/png', quality:1, width:14000, height:4000});
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url
        a.download = '收益';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        document.body.removeChild(canvas);
      });
    },
  }
}
</script>

<style scoped>
>>>.spreadDialogVisible .el-dialog__body{
  overflow:unset;
  padding: 0 !important;
  border: 0 !important;
  box-sizing: border-box;
}
>>>.spreadDialogVisible .el-dialog__header{
  padding: 0;
}
>>> .spreadDialogVisible .el-dialog__body>div{
  background: linear-gradient(180deg, #171953 0%, #10151D 100%, #10151D 100%) !important;
  /*background: linear-gradient(180deg, #E3ECFB 0%, #B1C8FA 100%) !important;*/
}
#btn-down:hover{
  background: #071345;
  opacity: 0.5;
  color: #F3F7FF !important;
}

@media screen and (max-width: 900px) {
  /deep/.spreadDialogVisible>div {
    max-height:100%;
  }
}
</style>
